(function ($) {

    function fadeStep() {
        var topScreen = jQuery(document).scrollTop();
        var step = $('.process-steps .step')
        if($('.page-template-courses').length){
          step = $('.course-block')
        }

        var bottomScreen = jQuery(document).scrollTop() + jQuery(window).height()
        step.each(function (i, el) {
            var $this = jQuery(this);
            var topSection = $this.offset().top;
            var bottomSection = $this.offset().top + $this.height();

            if (topSection > topScreen && topSection < bottomScreen) {
                $this.addClass('visible')
            } else if (bottomSection > topScreen && bottomSection < bottomScreen) {
                $this.addClass('visible')
            } else if ((topSection < topScreen && bottomSection > bottomScreen)) {
                $this.addClass('visible')
            } else {
                $this.removeClass('visible')
            }

        })
    }

    // function stepFive() {
    //
    //     var step = $('.process-steps .step-5')
    //     var bottomScreen = $(document).scrollTop() + $(window).height()
    //
    //     var topSection = $('.process-steps .step-5').offset().top;
    //
    //     // if (bottomScreen > topSection) {
    //     //
    //     // }
    // }
    function unactiveLink(){
        if ($('.wp-block-button__link').length && $('.wp-block-button__link').attr('href').length == 0) {
            $('.wp-block-button__link').css('cursor', 'default')

        }
    }
    $(document).ready(function () {

        var $window = $(window);
        const question = $('.question-block .question')
        // fadeStep();
        unactiveLink()
        $('.about-us-content .wp-block-column a').click(function () {
            let id = $('.about-us-content .wp-block-column a').attr('href')
            console.log(id)
        })



        if ($('body').hasClass('page-template-alternative-treatments')) {
            setTimeout(function () {
                var id = window.location.hash;
                var s = $(id).offset().top - $('#header').outerHeight()
                $('html, body').animate({
                    scrollTop: s
                })
            }, 100)
            
        }

        $('.wp-block-button__link').click(function (event) {
            if ($('.wp-block-button__link').length && $('.wp-block-button__link').attr('href').length == 0) {
                event.preventDefault()
            }

        })


        question.click(function () {
            $(this).toggleClass('turn').parent().find('.answer').slideToggle()
        })

        if ($(window).width() <= 768) {
            $('.recovery-step-block').click(function () {
                $(this).find('.description').fadeToggle()
            })
        }

        $('[data-fancybox]').fancybox({

            caption: function (instance, item) {
                return $(this).parent().find('figcaption').html();
            },
            afterShow: function (instance, current, e) {
                this.$content.prepend(instance.$caption);
            }
        });

        $('.menu-opener').click(function () {
            $('#main-navigation').slideToggle();
            return false;
        });

        if ($(window).width() < 1024) {
            $(document).on('click', 'body', function (e) {
                if (!$(e.target).closest('#main-navigation').length) {
                    $('#main-navigation:visible').slideUp();
                }
            })
        }

        var step = $('.process-steps .step').slice(0, -1)

        if ($(window).width() > 820) {
            step.each(function (i) {
                $(this).delay((i++) * 500).fadeTo(100, 1);
            })
        }


        fixSizes();
        $('.testimonials-slider').slick({
            dots: true,
            infinite: true,
            arrows: false,
            speed: 700,
            slidesToShow: 1,
            rtl: true,
            autoplay: true,
            autoplaySpeed: 4000
        });
        $('.process-slider').slick({
            dots: true,
            infinite: true,
            arrows: false,
            speed: 600,
            slidesToShow: 1,
            rtl: true,
            autoplay: true,
            autoplaySpeed: 4000
        });



        var counterEndNumber = $('#counter').text()
        $('#counter').html('1');
        var cunterTimerFlag = true;
        $(window).scroll(function () {
            if($('.page-template-courses').length){
                fadeStep()
            }
            if ($(window).width() <= 821) {
                fadeStep()
            }else {
                if ($(window).scrollTop() > 10) {

                    var step = $('.process-steps .step-5')
                    step.addClass('visible')
                }
            }

         successCounter();

        });

      successCounter();


        function successCounter() {
            if (cunterTimerFlag && $window.scrollTop() > $('.success-block').offset().top - $window.height() * 0.7) {
                cunterTimerFlag = false;
                var counter = new CountUp('counter', counterEndNumber);
                if (!counter.error) {
                    counter.start();
                }
            }
        }


    });


    $(window).resize(function () {
        fixSizes();
    });

    function fixSizes() {
        $('.bottom-section .heading').equalizeHeight({
            equaltop: true
        });
        if ($(window).width() <= 820) {
            $('.process-steps .step').equalizeHeight({
                equaltop: false
            });
        }
    };


    $.fn.equalizeHeight = function (options) {

        var settings = $.extend({
            'maxWindowWidth': false,
            'equaltop': false
        }, options);
        this.css({'height': 'inherit'});
        if (!settings.maxWindowWidth || settings.maxWindowWidth < $(window).width()) {
            var maxHeight = 0;
            var currentTop = false;
            var tempArray = [];
            if (!settings.equaltop) {
                this.each(function (index, el) {
                    maxHeight = Math.max(maxHeight, $(el).css('box-sizing') == 'border-box' ? $(el).innerHeight() : $(el).height());
                });
                this.css({'height': maxHeight + 'px'});
            }
            else {
                this.each(function (index, el) {
                    if (currentTop === false || Math.abs(currentTop - $(el).position().top) > 5) {
                        $(tempArray).css({'height': maxHeight + 'px'});
                        maxHeight = 0;
                        currentTop = Math.floor($(el).position().top);
                        tempArray = [];
                    }
                    maxHeight = Math.max(maxHeight, $(el).css('box-sizing') == 'border-box' ? $(el).innerHeight() : $(el).height());
                    tempArray.push(el);
                });
                $(tempArray).css({'height': maxHeight + 'px'});
            }
        }
    };


})(jQuery)
